import * as _getIntrinsic2 from "get-intrinsic";
var _getIntrinsic = _getIntrinsic2;
try {
  if ("default" in _getIntrinsic2) _getIntrinsic = _getIntrinsic2.default;
} catch (e) {}
var exports = {};
var GetIntrinsic = _getIntrinsic;

/** @type {import('.')} */
var $defineProperty = GetIntrinsic("%Object.defineProperty%", true) || false;
if ($defineProperty) {
  try {
    $defineProperty({}, "a", {
      value: 1
    });
  } catch (e) {
    // IE 8 has a broken defineProperty
    $defineProperty = false;
  }
}
exports = $defineProperty;
export default exports;